import React, { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setheadermenuData } from "../reducers/HeaderMenuReducer";
import CompanySearch from "../components/CompanySearch";
import { showsubscriptionmodal } from "../reducers/SubscriptionModalReducer";
import { GAEvenet } from "../API/GoogleAnalytics";
import SearchBox from "../components/SearchBox";
import { removeSearch } from "../API/LocalStore";
import { Button } from "@mui/material";
import ashomPlus from "../images/ashom_plus.png";
import xlsxDownload from "../images/xlsx_download.png";
import { getUserdata } from "../API/Userapis";

const Companiespage = () => {
  const [userData, setUserData] = useState({});
  let params = useParams();
  let company_id = params.id;
  const navigate = useNavigate();
  const GoToAshomPlus = (e) => {
    e.preventDefault();
    navigate("/ashom-plus");
  };
  const GoToXLSXDownload = (e) => {
    e.preventDefault();
    navigate("/ashom-plus-financials");
  };
  const GoToCompany = (e) => {
    e.preventDefault();
    navigate("/financials");
  };
  const GoToNews = (e) => {
    e.preventDefault();
    navigate("/news");
  };

  const GoToStockPrice = (e) => {
    e.preventDefault();
    removeSearch("listData");
    removeSearch("countries");
    removeSearch("selectedCountry");
    removeSearch("selectedChildCountry");
    navigate("/stock-price");
  };

  const dispatch = useDispatch();
  useEffect(() => {
    getUserdata().then((meta) => {
      // setUserData(meta);
      setUserData(meta);

      console.log("get user details at home page", meta.subscription_type);
    });

    dispatch(
      setheadermenuData({ currentpath: "/home", headerfootershow: true })
    );
    GAEvenet();
    let isFirstTimeUser = sessionStorage.getItem("firsttimeuser");
    if (isFirstTimeUser) {
      sessionStorage.removeItem("firsttimeuser");
      dispatch(showsubscriptionmodal({ value: true }));
    }
  }, []);

  return (
    <div className="d-flex justify-content-center">
      <div
        style={{ minHeight: "90vh" }}
        className="container homepage_container section_divider"
      >
        <div className="row global_search_box">
          <div className="col-md-6 offset-md-3" style={{ zIndex: "50" }}>
            <SearchBox />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 card homepage_center_main_div">
            <div className="row homepagebothicondiv">
              {/* <div className="col-md-3">
                <div className="homepage_icon_div">
                  <div className="row icon_container_row">
                    <div className="col homepage_icon_col">
                      <a
                        onClick={GoToAshomPlus}
                        href="about:blank"
                        className="nolink homepage_icon_container card"
                      >
                        <img
                          alt="Image not found."
                          className="ashom_plus_icon"
                          srcSet={ashomPlus}
                        />
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="homepage_icon_title_div">
                      <h3>Ashom+</h3>
                    </div>
                    <span className="homepage_icon_span">
                      Multi-dimensional financial Analysis
                    </span>
                  </div>
                  <br />
                </div>
              </div> */}
              
                <div className="col-md-3">
                  <div className="homepage_icon_div">
                    <div className="row icon_container_row">
                      <div className="col homepage_icon_col">
                        <a
                          onClick={GoToXLSXDownload}
                          href="about:blank"
                          className="nolink homepage_icon_container card"
                        >
                          <img
                            alt="Image not found."
                            className="homepage_icons"
                            srcSet={xlsxDownload}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="row">
                      <div className="homepage_icon_title_div">
                        <h3>XLSX Download</h3>
                      </div>
                      <span className="homepage_icon_span">
                        Download company reports swiftly in XLSX format for
                        streamlined data analysis and decision-making,
                        compatible with major spreadsheet software.
                      </span>
                    </div>
                    <br />
                  </div>
                </div>
              <div className="col-md-3">
                <div className="homepage_icon_div">
                  <div className="row icon_container_row">
                    <div className="col homepage_icon_col">
                      <a
                        onClick={GoToCompany}
                        href="about:blank"
                        className="nolink homepage_icon_container card"
                      >
                        <img
                          alt="Image not found."
                          className="homepage_icons"
                          srcSet={"./assets/icons/CompanyAnalysis.png"}
                        />
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="homepage_icon_title_div">
                      <h3>Company Financial Reports</h3>
                    </div>
                    <span className="homepage_icon_span">
                      View quarterly and annual financial statements, auditors’
                      opinion, and complete set of company financial notes
                      covering 700+ traded equities in the GCC countries (KSA,
                      UAE, Kuwait, Bahrain, Qatar, Oman)
                    </span>
                  </div>
                  <br />
                </div>
              </div>
              <div className="col-md-3">
                <div className="homepage_icon_div">
                  <div className="row icon_container_row">
                    <div className="col homepage_icon_col">
                      <a
                        onClick={GoToStockPrice}
                        href="about:blank"
                        className="nolink homepage_icon_container card"
                      >
                        <img
                          alt="Image not found."
                          className="homepage_icons"
                          srcSet={"./assets/icons/stock_price.png"}
                        />
                      </a>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="homepage_icon_title_div">
                      <h3>Stock Price</h3>
                    </div>
                    <span className="homepage_icon_span">
                      GCC market stock prices
                    </span>
                  </div>
                  <br />
                </div>
              </div>
              <div
                className="col-md-3">
                <div className="row icon_container_row">
                  <div className="col homepage_icon_col">
                    <div className="homepage_icon_div">
                      <a
                        className="homepage_icon_container nolink card"
                        href="about:blank"
                        onClick={GoToNews}
                      >
                        <img
                          alt="Image not found."
                          className="homepage_icons"
                          srcSet={"./assets/icons/News.png"}
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="homepage_icon_title_div">
                      <h3>News</h3>
                    </div>
                    <span className="homepage_icon_span">
                      Browse up-to-date news covering the GCC countries
                    </span>
                  </div>
                </div>
              </div>
              {/* <Link to={`/company/${company_id}/download`}>
                                <div className="download_btn_home">
                                    <Button variant="contained">
                                        Screener
                                    </Button>
                                </div>
                            </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Companiespage;
