import React, { useEffect, useState } from "react";
import crossIcon from "../images/cross-icon.png";
import closeIcon from "../images/close-icon.png";
import tickIcon from "../images/15-Checked.png";
import { Modal } from "react-bootstrap";

const PopupWarning = ({ message, show, handleClose }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      backdrop="static"
      className="modal fade"
    >
      <div
        style={{ background: "#FF7474", color: "white" }}
        className="modal-header"
      >
        <h5 className="modal-title" id="exampleModalCenterTitle">
          Required Selections
        </h5>
        <button
          type="button"
          className="close"
          //   data-dismiss="modal"
          //   aria-label="Close"
          onClick={handleClose}
        >
          <span aria-hidden="true">
            <img className="close-img" src={closeIcon} alt="Close-Icon" />
          </span>
        </button>
      </div>
      {/* <div className="modal-body documentModalBody">{Document_details}</div> */}
      <div className="modal-body documentModalBody">
        <div className="mb-3 modal-body-content">{message}</div>
      </div>
    </Modal>
  );
};

export default PopupWarning;
