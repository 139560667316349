import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Toast, ToastContainer } from "react-bootstrap";
import {
  getCompanyYears,
  checkCompanyDocuments,
  getSingleCompany,
  requestOpenCompanyApi,
  getFinancialNews,
  userEventAPI,
  getUserdata,
} from "../API/Userapis";
import {
  Skeleton,
  Grid,
  Paper,
  Card,
  Chip,
  Divider,
  CardActionArea,
  IconButton,
  Avatar,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  getFlag,
  setDocumentUrl,
  setDocumentOpenName,
  setDocumentCompanySymbol,
  getUserToken,
} from "../API/LocalStore";
import { getRemainsVisits } from "../API/Userapis";
import Documentdetailppopup from "../popup/DocumentDetailPPopup";
import AlertPopup from "../popup/AlertPopup";
import ForumNewsItem from "../components/ForumNewsItem";
import { setcompanybycountryval } from "../reducers/CompanyByContry";
import { useDispatch } from "react-redux";
import { setheadermenuData } from "../reducers/HeaderMenuReducer";
import { GAEvenet } from "../API/GoogleAnalytics";
import { useConfirm } from "material-ui-confirm";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import el from "date-fns/esm/locale/el/index.js";
import download_icon from "../images/download_icon.png";
import video from "../images/video.mp4";
import XLSXVideoPopup from "../popup/XLSXVideoPopup";
import PopupWarning from "../popup/PopupWarning";
const FinaciallyReportContent = (props) => {
  let params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let company_id = params.id;
  const [documentString, setDocumentString] = useState([]);
  const [year, setYear] = useState("");
  const [reportType, setReportType] = useState("");
  const [year1, setYear1] = useState("");
  const [documentsAvailable, setDocumentsAvailable] = useState([]);
  const [CompanyYears, setCompanyYears] = useState([]);
  const [SelectedYear, setSelectedYear] = useState("");
  const [SelectedPeriod, setSelectedPeriod] = useState("annual");
  const [Companydocuments, setCompanydocuments] = useState([]);
  const [Company_country, setCompany_country] = useState("");
  const [Company_name, setCompany_name] = useState("");
  const [Company_symbol, setCompany_symbol] = useState("");
  const [Company_image, setCompany_image] = useState("");
  const [hasSeenThisCompany, sethasSeenThisCompany] = useState(false);
  const [Remains_visits, setRemains_visits] = useState(0);
  const [isDetailModalShow, setisDetailModalShow] = useState(true);
  const [ModalDocumentTitle, setModalDocumentTitle] = useState("");
  const [ModalDocumentDetails, setModalDocumentDetails] = useState("");
  const [hasMoreNews, sethasMoreNews] = useState(false);
  const [isNewsLoaded, setisNewsLoaded] = useState(false);
  const [isYearsLoaded, setisYearsLoaded] = useState(false);
  const [News, setNews] = useState([]);
  const [Delisted_date, setDelisted_date] = useState("");
  const [Last_report, setLast_report] = useState("");
  const [MaxVisits, setMaxVisits] = useState(0);
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [videoShow, setVideoShow] = useState(false);
  const [accessLimit, setAccessLimit] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [userData, setUserData] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [popWarning, setPopWarning] = useState(false);
  const [popMessage, setPopMessage] = useState("");
  const token = getUserToken();
  const base_url = `${process.env.REACT_APP_API_ENDPOINT}`;
  const toggleToast = () => setShowToast(!showToast);
  useEffect(() => {
    checkCompanyDocuments(company_id).then((data) => {
      setDocumentsAvailable(data);
    });

    getUserdata().then((meta) => {
      // setUserData(meta);
      setUserData(meta);
      console.log("get user details at home page", meta.subscription_type);
    });
  }, []);

  const GoToDocumentView = (ref_url, doc_name) => {
    const token = getUserToken();
    if (token) {
      if (Remains_visits != 0 || hasSeenThisCompany) {
        requestOpenCompanyApi(company_id, 1).then((res) => {
          console.log("reaspisabawe", res);
          setAccessLimit(res);
        });
        sethasSeenThisCompany(true);
        setDocumentUrl(ref_url);
        setDocumentOpenName(doc_name);
        console.log(Company_symbol);
        setDocumentCompanySymbol(Company_symbol);
        setIsChecked(true);
        // navigate("/pdfview");
        // window.open('https://docs.google.com/gview?url='+ref_url+'&embedded=true#:0.page.20', '_blank');
      } else {
        alert("You have not any remaining counts to see this document.");
        setIsChecked(false);
        return false;
      }
    } else
      navigate("/login", {
        state: {
          redirect_url: window.location.pathname,
        },
      });
  };

  useEffect(() => {
    dispatch(
      setheadermenuData({ currentpath: "/financials", headerfootershow: true })
    );
    GAEvenet();
    window.scrollTo(0, 0);
    getCompanyYears(company_id).then((meta) => {
      setCompanyYears(meta);
      setisYearsLoaded(true);
    });

    getSingleCompany(company_id).then((meta) => {
      setCompany_symbol(meta.SymbolTicker);
      setCompany_name(meta.Company_Name);
      setCompany_image(meta.image);
      setCompany_country(meta.Country);
      getFinancialNews(0, meta.Country, meta.SymbolTicker).then((metanews) => {
        setDelisted_date(meta.DelistingDate);
        if (metanews.data.length > 2) sethasMoreNews(true);
        setisNewsLoaded(true);
        setNews(metanews.data.slice(0, 2));
      });

      getRemainsVisits(meta.SymbolTicker).then((meta) => {
        var session_selected_year = sessionStorage.getItem("document_set_year");
        var session_selected_period = sessionStorage.getItem(
          "document_set_period"
        );
        sessionStorage.removeItem("document_set_year");
        sessionStorage.removeItem("document_set_period");

        let visited_companies = meta.visited_companies;
        let sm_arr = visited_companies.filter(
          (element, index, array) => element === company_id
        );

        var las_report_perod = meta.last_report
          .split("-")[0]
          .toLowerCase()
          .replaceAll(" ", "");
        var las_report_year = parseInt(meta.last_report.split("-")[1]);

        if (session_selected_period)
          las_report_perod = session_selected_period.toLowerCase();
        if (session_selected_year)
          las_report_year = parseInt(session_selected_year);

        // setDocumentYear(las_report_year);
        setDocumentPeriod(las_report_perod);
        setSelectedYear(las_report_year);
        checkCompanyDocuments(company_id).then((metaDocs) => {
          setCompanydocuments(metaDocs);
          setDocumentPeriod(las_report_perod);
        });
        setLast_report(meta.last_report);
        if (sm_arr.length > 0) {
          sethasSeenThisCompany(true);
        }
        setMaxVisits(meta.visit_data.max_companies);
        if (parseInt(meta.visit_data.max_companies) === 0) {
          setRemains_visits(-1);
        } else {
          setRemains_visits(meta.visit_data.remaining_visits);
        }
      });
    });
  }, []);

  useEffect(() => {
    userEventAPI(`click_company_financial_statements`);
    var session_selected_year = sessionStorage.getItem("document_set_year");
    var session_selected_period = sessionStorage.getItem("document_set_period");

    if (session_selected_year) {
      // sessionStorage.removeItem('document_set_year');
      // sessionStorage.removeItem('document_set_period');
      setSelectedYear(session_selected_year);
      getSingleCompany(company_id).then((meta) => {
        setCompany_name(meta.Company_Name);
        setCompany_image(meta.image);
        setCompany_country(meta.Country);
        getFinancialNews(0, meta.Country, meta.SymbolTicker).then(
          (metanews) => {
            setDelisted_date(meta.DelistingDate);
            if (metanews.data.length > 2) sethasMoreNews(true);
            setisNewsLoaded(true);
            setNews(metanews.data.slice(0, 2));
          }
        );
      });
      setSelectedPeriod(session_selected_period.toLowerCase());
      if (company_id)
        checkCompanyDocuments(company_id).then((metaDocs) => {
          setCompanydocuments(metaDocs);
        });
    }
  }, [company_id]);

  const setDocumentYear = (year) => {
    setSelectedYear(year);
    checkCompanyDocuments(company_id).then((meta) => {
      setCompanydocuments(meta);
    });
  };

  const setDocumentPeriod = (period) => {
    setSelectedPeriod(period);
    if (SelectedYear)
      checkCompanyDocuments(company_id).then((meta) => {
        setCompanydocuments(meta);
      });
  };

  const handleChangeYear = (event) => {
    setYear(event.target.value);
    setYear1("");
    var btns = document.querySelectorAll(".radioBtn");
    btns.forEach((item, ind) => {
      item.checked = false;
    });
  };
  const handleChangeYear1 = (event) => {
    setYear1(event.target.value);
  };
  const handleChangeQuarter = () => {
    setReportType("Q");
  };
  const handleChangeAnnual = () => {
    setReportType("A");
  };

  const documents = {
    document_name: "Financial Report",
    document_image: "/assets/icons/FinancialReport.png",
    document_str: "income_statement,balance_sheet,cash_flow_statement",
    // document_str: "financial_report",
    info: "An annual report is a financial summary of a company’s activities during the year along with management’s analysis of the company’s current financial position and future plans. Annual reports are prepared at the end of the fiscal year for external users to gain financial information about the inner workings of the company and what management plans to do in the future.",
  };

  function documentExists(documentName) {
    var flag = 0;
    documentsAvailable.map((el) => {
      if (el.name.toLowerCase() == documentName.toLowerCase()) {
        flag = 1;
      }
      if (
        documentName == "Financial Report" &&
        documentsAvailable.length == 3
      ) {
        flag = 1;
      }
    });
    if (flag == 1) {
      return true;
    } else {
      return false;
    }
  }

  const showDetailsPopUp = (document_name, event) => {
    setisDetailModalShow(true);
    setModalDocumentTitle(document_name);
    setModalDocumentDetails(documents.info);
  };

  const downloadUrlWithFile = () => {
    let documentType = "Financial Report";
    documentString.map((el) => {
      documentType += `${el},`;
    });
    if (
      year != "" &&
      year1 != "" &&
      reportType != "" &&
      documentType != "" &&
      isChecked
    ) {
      if (userData.subscription_type == "Free") {
        setPopMessage(
          "Access denied. You do not have an active subscription plan."
        );

        showWarningPopup();
        return;
      }
      if (accessLimit.status == false) {
        setPopMessage(accessLimit.message);
        // alert(accessLimit.message);
        showWarningPopup();

        return;
      }
      setLoader(true);
      axios({
        url: `${base_url}api/webservice/export_financial_data/${company_id}/${year}/${year1}/${reportType}/?type=${documentType}`, //your url
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const href = URL.createObjectURL(response.data);

        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `report.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        setLoader(false);
      });
    } else {
      handleShow();
    }
  };

  function getdocumentLink(documentName) {
    console.log(documentName);
    var data = Companydocuments.filter((e) => {
      if (e.document_name == documentName) {
        console.log(e);
        return e;
      }
    });
    if (data.length > 0) return data[0].document_link;
    else return data.document_link;
  }

  function checkboxClicked(index) {
    // let oldArr = [];
    var chk = document.querySelectorAll(".checkbox");
    chk.forEach((item, ind) => {
      if (ind == index) {
        setDocumentString((documentString) => [...documentString, item.value]);
      }
    });
  }

  function checkboxUnClicked(index) {
    var chk = document.querySelectorAll(".checkbox");
    chk.forEach((item, ind) => {
      if (ind == index) {
        let elIndex = documentString.indexOf(item.value);
        setDocumentString((oldArr) => [
          ...oldArr.slice(0, elIndex),
          ...oldArr.slice(elIndex + 1, oldArr.length),
        ]);
      }
    });
  }

  function financialReportCheckBoxClicked(index) {
    var chk = document.querySelectorAll(".checkbox");
    chk.forEach((item, ind) => {
      if (ind == index) {
        if (item.checked == false) {
          item.checked = true;
          setDocumentString([item.value]);
        } else {
          item.checked = false;
          checkboxUnClicked(index);
        }
      } else {
        item.checked = false;
      }
    });
  }

  function financialReportCheckBoxUnClicked(index) {
    var chk = document.querySelectorAll(".checkbox");
    chk.forEach((item, ind) => {
      if (documents[ind].document_name == "Financial Report") {
        item.checked = false;
        let elIndex = documentString.indexOf(item.value);
        if (elIndex > -1) {
          console.log("elIndex", elIndex);
          setDocumentString((oldArr) => [
            ...oldArr.slice(0, elIndex),
            ...oldArr.slice(elIndex + 1, oldArr.length),
          ]);
        }
      }
    });
  }

  function clickCheckbox(index) {
    var chk = document.querySelector(".checkbox");
    console.log("chk", chk);
    chk.forEach((item, ind) => {
      if (ind == index) {
        if (documents[index].document_name == "Financial Report") {
          financialReportCheckBoxClicked(index);
        } else {
          financialReportCheckBoxUnClicked(index);
          if (item.checked == false) {
            item.checked = true;
            checkboxClicked(index);
          } else {
            item.checked = false;
            checkboxUnClicked(index);
          }
        }
      }
    });
    console.log(documentString);
    // financialReportCheckBoxClicked(index);
    // checkboxClicked(index);
  }

  const handleBack = () => {
    navigate(-1);
  };

  const handlegotocountry = (counr) => {
    dispatch(setcompanybycountryval(Company_country));
    navigate("/financials");
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const closeWarningPopup = () => setPopWarning(false);
  const showWarningPopup = () => setPopWarning(true);
  const handleVideoClose = () => setVideoShow(false);
  const handleVideoShow = () => setVideoShow(true);

  return (
    <>
      <Documentdetailppopup
        Document_title={documents.document_name}
        Document_details={ModalDocumentDetails}
        isDetailModalShow={documents.info}
      />
      <AlertPopup
        year={year}
        year1={year1}
        reportType={reportType}
        isDocument={documentString}
        show={show}
        handleClose={handleClose}
      />
      <XLSXVideoPopup
        videoShow={videoShow}
        handleVideoClose={handleVideoClose}
      />
      <PopupWarning
        message={popMessage}
        show={popWarning}
        handleClose={closeWarningPopup}
      />

      <div className=" ">
        <div className="  ">
          <div className="">
            <div className="">
              {/* <Card className=" company_details_period_section"> */}
              <div>
                <div className="row px-5 mt-4">
                  <div className="col-12 col-lg-3 mb-3 mb-lg-0 report_instruction_div">
                    <div className="report_excel_data_filter">
                      Steps to Download Excel File
                    </div>
                    <div className="report_instruction_block">
                      <div className="report_instruction_block_heading">
                        STEP 1:
                      </div>
                      <div className="report_instruction_block_subheading">
                        Choose the period for which you want to download your
                        Excel report.
                      </div>
                    </div>
                    <div className="report_instruction_block">
                      <div className="report_instruction_block_heading">
                        STEP 2:
                      </div>
                      <div className="report_instruction_block_subheading">
                        Click to choose the report type, either quarterly or
                        annual.
                      </div>
                    </div>
                    <div className="report_instruction_block">
                      <div className="report_instruction_block_heading">
                        STEP 3:
                      </div>
                      <div className="report_instruction_block_subheading">
                        Click on the financial statement(s) you would like to
                        download.
                      </div>
                    </div>
                    <div className="report_instruction_block">
                      <div className="report_instruction_block_heading">
                        STEP 4:
                      </div>
                      <div className="report_instruction_block_subheading">
                        Click on the "Download Excel Report" button for the
                        report to start downloading.
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-9 px-0 px-lg-3">
                    <div className="d-flex justify-content-between ">
                      <div className="border px-3 rounded-lg block_custom_width  py-3">
                        <div className=" font-weight-bold mb-2">Period</div>
                        <div className="d-flex justify-content-between">
                          <select
                            className="rounded-lg  select_custom_class custom-select"
                            aria-label="Example select"
                            value={year}
                            label="Year"
                            onChange={handleChangeYear}
                          >
                            <option id="demo-simple-select">From (year)</option>
                            {CompanyYears.length == 0 && !isYearsLoaded ? (
                              <div className="d-flex">
                                <Skeleton
                                  variant="rectangular"
                                  width={70}
                                  style={{ borderRadius: "20px" }}
                                  height={30}
                                />
                              </div>
                            ) : (
                              CompanyYears.map(function (value, index, array) {
                                return (
                                  <option
                                    key={index}
                                    // onClick={() => setDocumentYear(value.year)}
                                    label={value.year}
                                    value={value.year}
                                    // variant={
                                    //   SelectedYear == value.year ? "" : "outlined"
                                    // }
                                  >
                                    {value.year}
                                  </option>
                                );
                              })
                            )}
                            {isYearsLoaded && CompanyYears.length == 0 ? (
                              <span className="no_news_txt mt-0 ml-2">
                                Years records not available for this company.
                              </span>
                            ) : (
                              ""
                            )}
                          </select>

                          <select
                            className="rounded-lg  select_custom_class custom-select"
                            aria-label="Example select"
                            value={year1}
                            label="Year"
                            onChange={handleChangeYear1}
                          >
                            <option value="">To (year)</option>
                            {CompanyYears.length == 0 && !isYearsLoaded ? (
                              <div className="d-flex">
                                <Skeleton
                                  variant="rectangular"
                                  width={70}
                                  style={{ borderRadius: "20px" }}
                                  height={30}
                                />
                              </div>
                            ) : (
                              CompanyYears.map(function (value, index, array) {
                                if (value.year >= year) {
                                  return (
                                    <option
                                      key={index}
                                      // onClick={() => setDocumentYear(value.year)}
                                      label={value.year}
                                      value={value.year}
                                      // variant={
                                      //   SelectedYear == value.year ? "" : "outlined"
                                      // }
                                    >
                                      {value.year}
                                    </option>
                                  );
                                }
                              })
                            )}
                            {isYearsLoaded && CompanyYears.length == 0 ? (
                              <span className="no_news_txt mt-0 ml-2">
                                Years records not available for this company.
                              </span>
                            ) : (
                              ""
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="border px-3 rounded-lg block_custom_width  py-3">
                        <div className=" font-weight-bold mb-2">
                          Report Type
                        </div>
                        <div className="d-flex justify-content-between">
                          <select
                            className="rounded-lg  .select_custom_class_width_100 custom-select"
                            aria-label="Example select"
                            onChange={(e) => {
                              setReportType(e.target.value);
                            }}
                          >
                            <option value="">Select Type</option>
                            <option value="Q">Quarter</option>
                            <option value="A">Annual</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="border my-3 px-3 py-3 rounded-lg">
                      <div className="font-weight-bold mb-2">
                        Select Report(s)
                      </div>
                      <div
                        className="selected_report_section_handing"
                        // onClick={() =>
                        //   !token
                        //     ? navigate("/login", {
                        //         state: {
                        //           redirect_url: window.location.pathname,
                        //         },
                        //       })
                        //     : documentExists(documents.document_name)
                        //     ? GoToDocumentView(
                        //         getdocumentLink(documents.document_name),
                        //         documents.document_name
                        //       )
                        //     : false
                        // }
                      >
                        {/* <div className=""> */}
                        {/* <img
                          className="lock_handling"
                          src={
                            hasSeenThisCompany || Remains_visits != 0
                              ? "/assets/icons/unlock_black.png"
                              : "/assets/icons/lock_black.png"
                          }
                        /> */}
                        <div className="lock_container">
                          <img
                            className="lock_handling"
                            src={
                              hasSeenThisCompany || Remains_visits != 0
                                ? "/assets/icons/unlock_black.png"
                                : "/assets/icons/lock_black.png"
                            }
                          />
                        </div>
                        {/* </div> */}
                        {/* <span
                          data-toggle="modal"
                          data-target="#documentDetailPoppup"
                          onClick={(event) =>
                            showDetailsPopUp(documents.document_name, event)
                          }
                          className="com_details_exclamatoy_icon"
                          aria-label="delete"
                        >
                          <img src="/assets/icons/information.svg" />
                        </span> */}
                        <div
                          data-toggle="modal"
                          data-target="#documentDetailPoppup"
                          onClick={(event) =>
                            showDetailsPopUp(documents.document_name, event)
                          }
                          className="com_details_exclamatoy_icon_finan"
                          aria-label="delete"
                        >
                          <img src="/assets/icons/information.svg" />
                        </div>
                        <div className="input_handle_color d-flex align-items-center px-3 py-2">
                          <input
                            type="checkbox"
                            className="checkbox input_custom_sizes mr-3 "
                            checked={isChecked}
                            style={{
                              accentColor: "rgba(25, 23, 61, 1)",
                            }}
                            onChange={() =>
                              !token
                                ? navigate("/login", {
                                    state: {
                                      redirect_url: window.location.pathname,
                                    },
                                  })
                                : documentExists(documents.document_name)
                                ? GoToDocumentView(
                                    getdocumentLink(documents.document_name),
                                    documents.document_name
                                  )
                                : false
                            }
                          />
                          <img
                            className="mr-3"
                            src={documents.document_image}
                            style={{ width: "15%" }}
                          />
                          <span className="">{documents.document_name}</span>
                        </div>
                        {!token || documentExists(documents.document_name) ? (
                          ""
                        ) : (
                          <div className="noDocumentAvailableCard_new">
                            <span> Not Available</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex justify-content-end company_details_visits_second mb-2">
                      {Remains_visits !== -1 ? (
                        <span style={{ color: "#001B3D" }}>
                          Remaining count :{" "}
                          {Remains_visits ? Remains_visits : 0} of{" "}
                          {MaxVisits ? MaxVisits : 0}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className=" d-flex justify-content-end">
                      <div className="pr-3 ">
                        <Button
                          variant="contained"
                          className="excel-btn"
                          onClick={handleVideoShow}
                          style={{
                            backgroundColor: "#000030",
                          }}
                        >
                          <React.Fragment>Watch Tutorial</React.Fragment>
                        </Button>
                      </div>
                      <div className="">
                        <Button
                          variant="contained"
                          className="excel-btn"
                          onClick={downloadUrlWithFile}
                          style={{
                            backgroundColor: `${
                              // loader ? " #72C4F1 " :
                              "#000030"
                            }`,
                          }}
                        >
                          <React.Fragment>
                            <img
                              className="pr-2"
                              src={download_icon}
                              alt="download"
                            />
                            Download Excel Report
                          </React.Fragment>
                          {/* )} */}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </Card> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinaciallyReportContent;
