import React, { useEffect, useState } from "react";
import { useRef } from "react";

const Paymentgateway = ({
  CheckoutAmount,
  pleaseSubscribeMe,
  plansExpiryDate,
  setSubscription_type,
  setCheckout,
}) => {
  const [paid, setPaid] = useState(false);
  const [error, setError] = useState(null);
  const paypalRef = useRef();

  useEffect(() => {
    window.paypal
      .Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: "Your description",
                amount: {
                  currency_code: "USD",
                  value: CheckoutAmount,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          console.log("order and action", order);
          setPaid(true);
          setCheckout(false);
          pleaseSubscribeMe(
            setSubscription_type,
            CheckoutAmount,
            plansExpiryDate,
            data.orderID
          );
          setSubscription_type(setSubscription_type);
        },
        onError: (err) => {
          console.log("error==========>", err);
        },
      })
      .render(paypalRef.current);
    // })
  }, [CheckoutAmount]);

  if (paid) {
    return <button className="subscribe_btn subscribed">Subscribed</button>;
  }

  // If any error occurs
  if (error) {
    return (
      <div style={{ color: "red" }}>
        Error Occurred in processing payment.! Please try again.
      </div>
    );
  }

  return (
    <div className="payment-div">
      <div>
        <div ref={paypalRef} />
      </div>
    </div>
  );
};

export default Paymentgateway;
